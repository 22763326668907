import axios from 'axios';
import initInterceptors from './interceptors';
import { ICart } from '../interfaces/ICart';
import { getCurrentLang } from '../utils/Local';


const getToken = (): string => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('t');
    return token ?? '';
}

const getLanguage = () => {
    return getCurrentLang()
}

const formatToken = (token: string) => {
    return `Bearer ${token}`;
};


const api = axios.create({
    timeout: 60000,
    headers: {
        'Authorization': formatToken(getToken()),
        'x-api-key': process.env.REACT_APP_API_KEY || '',
        'Language': getLanguage()
    },
});


initInterceptors(api);

export default class apiService {

    static submitContactForm = (formData: any) => {
        const url = process.env.REACT_APP_API_CONTACT_FORM_URL || ''
        return api.post(url, {
            data: formData
        });
    }

    static fetchProduct = (productSlug: string) => {
        const url = process.env.REACT_APP_API_URL || ''
        return api.get(`${url}/product/${productSlug}`);
    }

    static fetchBlog = (blogId: string) => {
        const url = process.env.REACT_APP_API_URL || ''
        return api.get(`${url}/blogs/${blogId}`);
    }

    static fetchBlogs = (limit: number, last_key?: any) => {
        const url = process.env.REACT_APP_API_URL || ''
        return api.get(`${url}/blogs`, {
            params: {
                limit,
                last_key: JSON.stringify(last_key)
            }
        });
    }

    static checkout = (cart: ICart) => {
        const url = process.env.REACT_APP_API_URL || ''

        if (cart.id && cart.id !== '') {
            return api.patch(`${url}/update-payment-intent/${cart.id}`, cart);
        } else {
            return api.post(`${url}/create-payment-intent`, cart);
        }

    }

    static unsubscribe = (token: string) => {
        const url = process.env.REACT_APP_API_URL || ''
        return api.post(`${url}/preferences/unsubscribe`, {
            token
        });
    }

}
