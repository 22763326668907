import { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBug } from "@fortawesome/free-solid-svg-icons"
import i18n from "../i18n"
import apiService from '../services/api';
import { NavLink } from "react-router-dom"
import { getUrlLangPrefix } from "../utils/Local"
import { Button } from "react-bootstrap"
import { ReactComponent as FaHome } from '../assets/icons/faHome.svg';
import { ReactComponent as FaCheckCircle } from '../assets/icons/faCheckCircle.svg';
import Loader from './Loader.component'


type UnsubscribeState = "loading" | "success" | "error";

const UnsubscribeComponent = () => {

    const [status, setStatus] = useState<UnsubscribeState>("loading");
    const { t } = i18n;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("t");

        if (!token) {
            setStatus("error");
            return;
        }

        const unsubscribeEmail = async () => {
            try {
                await apiService.unsubscribe(token);
                setStatus("success");
            } catch (error) {
                console.error("Failed to unsubscribe:", error);
                setStatus("error");
            }
        };

        unsubscribeEmail();
    }, [])

    if (status === "loading") {
        return (<Loader />)
    }

    if (status === "success") {
        return (<>
            <div className="d-flex flex-column justify-content-center align-items-center position-absolute h-100 w-100 gap-4">
                <FaCheckCircle />

                <NavLink to={`${getUrlLangPrefix()}/`} className='mt-2'>
                    <Button variant="outline-primary d-flex justify-content-center gap-2 ps-4 pe-4 pt-2 pb-2" >
                        <FaHome />
                        <span>{t('go_to_homepage')}</span>
                    </Button>
                </NavLink>
            </div>
        </>);
    }

    if (status === "error") {
        return (<>
            <div className="d-flex flex-column justify-content-center align-items-center position-absolute h-100 w-100 gap-4">
                <FontAwesomeIcon icon={faBug} size="4x" />
                {t('_error_message')}

                <NavLink to={`${getUrlLangPrefix()}/`} className='mt-2'>
                    <Button variant="outline-primary d-flex justify-content-center gap-2 ps-4 pe-4 pt-2 pb-2" >
                        <FaHome />
                        <span>{t('go_to_homepage')}</span>
                    </Button>
                </NavLink>
            </div>
        </>);
    }

    return null;
}

export default UnsubscribeComponent